import { UNIT_DETAILS, UTILITY_TYPES } from 'static/utility-units'
import { CONVERSION_ITEMS } from './constants'
import { SCHEDULE_TYPES } from 'static/dictionaries/schedules'

const sharedSubFields = (title, utility) => {
  utility =
    utility === 'electric'
      ? 'electricity'
      : utility === 'naturalgas'
      ? 'naturalGas'
      : utility
  return [
    {
      name: `${title} Average Usage`,
      value: `${utility}.totalUsage`
    },
    {
      name: `${title} Average Cost`,
      value: `${utility}.totalUsageCost`
    },
    {
      name: `${title} Cost Per Square Foot`,
      value: `${utility}.costPerSquareFoot`
    },
    {
      name: `${title} Days in Period`,
      value: `${utility}.daysInPeriod`
    },
    {
      name: `${title} Average Daily`,
      value: `${utility}.avgDaily`
    },
    {
      name: `${title} Average Annual Energy`,
      value: `${utility}.averageAnnualEnergy`
    },
    {
      name: `${title} Average Annual Cost`,
      value: `${utility}.averageAnnualCost`
    },
    {
      name: `${title} GHG Emissions`,
      value: `${utility}.ghgEmissions`
    }
  ]
}

const electricitySubFields = (title, utility) => {
  utility =
    utility === 'electric'
      ? 'electricity'
      : utility === 'naturalgas'
      ? 'naturalGas'
      : utility
  return [
    {
      name: `${title} Average Demand`,
      value: `${utility}.totalDemand`
    },
    {
      name: `${title} Average Demand Cost`,
      value: `${utility}.totalDemandCost`
    },
    {
      name: `${title} Average Max Demand Value`,
      value: `${utility}.totalDemandMonthly`
    }
  ]
}

export const UtilityFields = Object.entries(UNIT_DETAILS).map(
  ([utility, { title }]) => {
    let dataPoints = {
      name: title,
      value: utility,
      subFields: [...sharedSubFields(title, utility)]
    }
    if (utility === UTILITY_TYPES.ELECTRICITY) {
      dataPoints.subFields.push(...electricitySubFields(title, utility))
    }
    return dataPoints
  }
)

export const UtilityFieldsWithTargets = Object.entries(UNIT_DETAILS).map(
  ([utility, { title }]) => {
    const _utility =
      utility === 'electric'
        ? 'electricity'
        : utility === 'naturalgas'
        ? 'naturalGas'
        : utility
    let dataPoints = {
      name: title,
      value: utility,
      subFields: [
        ...sharedSubFields(title, utility),
        {
          name: `${title} Target Reduction (%)`,
          value: `${_utility}.targetReduction`
        },
        {
          name: `${title} Usage Reduction`,
          value: `${_utility}.usageReduction`
        },
        {
          name: `${title} Cost Reduction`,
          value: `${_utility}.costReduction`
        },
        {
          name: `${title} GHG Emissions Reduction`,
          value: `${_utility}.ghgEmissionReduction`
        }
      ]
    }
    if (utility === UTILITY_TYPES.ELECTRICITY) {
      dataPoints.subFields.push(...electricitySubFields(title, utility))
    }
    return dataPoints
  }
)

const blendRateSubFields = Object.entries(UNIT_DETAILS).map(
  ([utility, { title }]) => {
    return {
      name: `${title} Average Blended Rate`,
      value: `rates.${utility}`
    }
  }
)

const ghgEmissionConversionFields = Object.entries(CONVERSION_ITEMS).reduce(
  (accum, current) => {
    const item = {
      name: current[1].label,
      value: `ghgEmissions.${current[0]}`
    }
    accum.push(item)
    return accum
  },
  []
)

export const OverviewFields = [
  {
    name: 'Summary',
    value: 'summary',
    subFields: [
      { name: 'Total Energy Cost', value: 'summary.totalCost' },
      {
        name: 'Total Cost per Square Foot',
        value: 'summary.totalCostPerSquareFoot'
      },
      { name: 'Total Energy Use', value: 'summary.totalEnergyUse' },
      { name: 'Site EUI (kBtu/ft²)', value: 'summary.siteEui' },
      { name: 'Total Water Use', value: 'summary.totalWaterUse' },
      { name: 'Water WUI (ccf/ft²)', value: 'summary.waterWui' },
      {
        name: 'CBECs Median Electricity EUI (kWh/ft²)',
        value: 'summary.CBECs Median Electricity EUI (kWh/SqFt)'
      },
      {
        name: 'CBECs Median Natural Gas EUI (therms/ft²)',
        value: 'summary.CBECs Median Natural Gas EUI (Therms/SqFt)'
      },
      {
        name: 'CBECs Median EUI - All Sources (kBtu/ft²)',
        value: 'summary.CBECs Median EUI - All Sources (kBtu/SqFt)'
      },
      {
        name: 'Energy Use Target',
        value: 'summary.energyUseTarget'
      },
      {
        name: 'Energy Cost Target',
        value: 'summary.energyCostTarget'
      },
      {
        name: 'Utility Cost Target',
        value: 'summary.utilityCostTarget'
      },
      {
        name: 'GHG Emission Target',
        value: 'summary.ghgEmissionTarget'
      },
      {
        name: 'GHG Emissions (mtCO2e)',
        value: 'ghgEmissions.totalEmissions'
      }
    ]
  },
  ...UtilityFieldsWithTargets,
  {
    name: 'Other Fuel',
    value: 'otherFuel',
    subFields: [
      { name: 'Other EUI (kWh/ft²)', value: 'otherFuel.waterWUI' },
      { name: 'Total Usage', value: 'otherFuel.totalUsage' },
      { name: 'Total Usage Percent', value: 'otherFuel.totalUsagePercent' },
      { name: 'Total Usage Cost', value: 'otherFuel.totalUsageCost' },
      {
        name: 'Total Usage Cost Percent',
        value: 'otherFuel.totalUsageCostPercent'
      },
      {
        name: 'Other Target Reduction (%)',
        value: `other.targetReduction`
      },
      {
        name: 'Other Usage Reduction',
        value: `other.usageReduction`
      },
      {
        name: 'Other Cost Reduction',
        value: `other.costReduction`
      },
      {
        name: 'Other GHG Emissions Reduction',
        value: `other.ghgEmissionReduction`
      }
    ]
  },
  {
    name: 'Rates',
    value: 'rates',
    subFields: blendRateSubFields
  },
  {
    name: 'GHG Emissions',
    value: 'ghgEmissions',
    subFields: [
      {
        name: 'Total Emissions (mtCO2e)',
        value: 'ghgEmissions.totalEmissions'
      },
      {
        name: 'GHG Intensity (kgCO2e/ft²)',
        value: 'ghgEmissions.ghgIntensity'
      },
      ...ghgEmissionConversionFields
    ]
  },
  {
    name: 'Portfolio Manager',
    value: 'portfolioManager',
    subFields: [
      {
        name: 'Latest Energy Star Portfolio Manager Score',
        value: 'portfolioManager.score'
      }
    ]
  },
  {
    name: 'Annual Cost Benchmark',
    value: 'annualCostBenchmark',
    subFields: [
      {
        name: 'CBECS/RECS Energy Cost - Your Building',
        value: 'annualCostBenchmark.you'
      },
      {
        name: 'CBECS/RECS Energy Cost - % vs Median',
        value: 'annualCostBenchmark.percentMedian'
      },
      {
        name: 'CBECS/RECS Energy Cost - Median',
        value: 'annualCostBenchmark.median'
      },
      {
        name: 'CBECS/RECS Energy Cost - 75th Percentile',
        value: 'annualCostBenchmark.percentile75'
      },
      {
        name: 'CBECS/RECS Energy Cost - 90th Percentile',
        value: 'annualCostBenchmark.percentile90'
      }
    ]
  },
  {
    name: 'Annual Usage Benchmark',
    value: 'annualUsageBenchmark',
    subFields: [
      {
        name: 'CBECS/RECS Energy Use - Your Building',
        value: 'annualUsageBenchmark.you'
      },
      {
        name: 'CBECS/RECS Energy Use - % vs Median',
        value: 'annualUsageBenchmark.percentMedian'
      },
      {
        name: 'CBECS/RECS Energy Use - Median',
        value: 'annualUsageBenchmark.median'
      },
      {
        name: 'CBECS/RECS Energy Use - 75th Percentile',
        value: 'annualUsageBenchmark.percentile75'
      },
      {
        name: 'CBECS/RECS Energy Use - 90th Percentile',
        value: 'annualUsageBenchmark.percentile90'
      }
    ]
  },
  {
    name: 'Degree Days',
    value: 'degree',
    subFields: [
      { name: 'HDD', value: 'degree.Hdd' },
      { name: 'CDD', value: 'degree.Cdd' }
    ]
  }
]
export const EndUseFields = [
  {
    name: 'CBECS/RECS',
    value: 'estimatedEndUseBreakdown',
    subFields: [
      {
        name: 'Percentages',
        value: 'estimatedEndUseBreakdown.percentage'
      }
    ]
  },
  {
    name: 'ASHRAE Level II',
    value: 'actualEndUseBreakdown',
    subFields: [
      { name: 'Total Use (kBtu)', value: 'actualEndUseBreakdown.totalUse' },
      {
        name: 'Electricity Use (kWh)',
        value: 'actualEndUseBreakdown.electricity.energyUse'
      },
      {
        name: 'Natural Gas Use (therms)',
        value: 'actualEndUseBreakdown.naturalGas.energyUse'
      },
      { name: 'Percentages', value: 'actualEndUseBreakdown.totalCost' }
    ]
  }
]

export const OperationFields = [
  { name: 'Occupancy Schedules', value: SCHEDULE_TYPES.OCCUPANCY },
  { name: 'Setpoint Schedules', value: SCHEDULE_TYPES.SETPOINT },
  { name: 'Operational Schedules', value: SCHEDULE_TYPES.OPERATIONAL }
]
export const ConstructionFields = [
  {
    name: 'Wall',
    value: 'wall',
    subFields: [
      { name: 'Name', value: 'wall.name', section: 'Properties' },
      { name: 'R-Value', value: 'wall.rvalue', section: 'Properties' },
      { name: 'U-Value', value: 'wall.uvalue', section: 'Properties' },
      { name: 'Comments', value: 'wall.comments', section: 'Properties' }
    ]
  },
  {
    name: 'Roof',
    value: 'roof',
    subFields: [
      { name: 'Name', value: 'roof.name', section: 'Properties' },
      { name: 'R-Value', value: 'roof.rvalue', section: 'Properties' },
      { name: 'U-Value', value: 'roof.uvalue', section: 'Properties' },
      { name: 'Comments', value: 'roof.comments', section: 'Properties' }
    ]
  },
  {
    name: 'Foundation',
    value: 'foundation',
    subFields: [
      {
        name: 'Name',
        value: 'foundation.name',
        section: 'Properties'
      },
      {
        name: 'R-Value',
        value: 'foundation.rvalue',
        section: 'Properties'
      },
      {
        name: 'U-Vlue',
        value: 'foundation.uvalue',
        section: 'Properties'
      },
      {
        name: 'Comments',
        value: 'foundation.comments',
        section: 'Properties'
      }
    ]
  },
  {
    name: 'Window',
    value: 'window',
    subFields: [
      { name: 'Name', value: 'window.name', section: 'Properties' },
      { name: 'R-Value', value: 'window.rvalue', section: 'Properties' },
      { name: 'U-Value', value: 'window.uvalue', section: 'Properties' },
      { name: 'Comments', value: 'window.comments', section: 'Properties' }
    ]
  },
  {
    name: 'Floor',
    value: 'exterior_floor',
    subFields: [
      { name: 'Name', value: 'exterior_floor.name', section: 'Properties' },
      {
        name: 'R-Value',
        value: 'exterior_floor.rvalue',
        section: 'Properties'
      },
      {
        name: 'U-Value',
        value: 'exterior_floor.uvalue',
        section: 'Properties'
      },
      {
        name: 'Comments',
        value: 'exterior_floor.comments',
        section: 'Properties'
      }
    ]
  },
  {
    name: 'Door',
    value: 'door',
    subFields: [
      { name: 'Name', value: 'door.name', section: 'Properties' },
      {
        name: 'R-Value',
        value: 'door.rvalue',
        section: 'Properties'
      },
      {
        name: 'U-Value',
        value: 'door.uvalue',
        section: 'Properties'
      },
      {
        name: 'Comments',
        value: 'door.comments',
        section: 'Properties'
      }
    ]
  }
]
export const LocationFields = [
  {
    name: 'Summary',
    value: 'summary',
    subFields: [
      {
        name: 'Use Type',
        value: 'summary.usetype',
        section: 'Properties'
      },
      {
        name: 'Space Type',
        value: 'summary.spaceType',
        section: 'Properties'
      },
      { name: 'Floor', value: 'summary.floor', section: 'Properties' },
      {
        name: 'Gross Use Type Area',
        value: 'summary.grossUseTypeArea',
        section: 'Properties'
      },
      {
        name: 'Gross Floor Area',
        value: 'summary.grossFloorArea',
        section: 'Properties'
      },
      {
        name: '% of Floor Area',
        value: 'summary.percentofFloorArea',
        section: 'Properties'
      },
      {
        name: '% of Use Type Area',
        value: 'summary.percentofUseTypeArea',
        section: 'Properties'
      },
      {
        name: '% of Common Area',
        value: 'summary.percentofCommonArea',
        section: 'Properties'
      },
      {
        name: '% of Tenant Area',
        value: 'summary.percentofTenantArea',
        section: 'Properties'
      },
      {
        name: '% of Conditioned Area',
        value: 'summary.percentofConditionedArea',
        section: 'Properties'
      },
      {
        name: '% of Unconditioned Area',
        value: 'summary.percentofUnconditionedArea',
        section: 'Properties'
      }
    ]
  },
  {
    name: 'Details',
    value: 'details',
    subFields: [
      {
        name: 'Use Type',
        value: 'details.usetype',
        section: 'Properties'
      },
      {
        name: 'Space Type',
        value: 'details.spaceType',
        section: 'Properties'
      },
      { name: 'Name', value: 'details.name', section: 'Properties' },
      { name: 'Floor', value: 'details.floor', section: 'Properties' },
      {
        name: 'Gross Floor Area',
        value: 'details.grossFloorArea',
        section: 'Properties'
      },
      {
        name: 'Conditioning',
        value: 'details.conditioning',
        section: 'Properties'
      },
      { name: 'User', value: 'details.user', section: 'Properties' },
      {
        name: 'Length',
        value: 'details.length',
        section: 'Properties'
      },
      { name: 'Width', value: 'details.width', section: 'Properties' },
      { name: 'Height', value: 'details.height', section: 'Properties' }
    ]
  }
]
export const ContactFields = [
  { name: 'Title', value: 'title' },
  { name: 'FirstName', value: 'firstName' },
  { name: 'LastName', value: 'lastName' },
  { name: 'company', value: 'company' },
  { name: 'Role', value: 'role' },
  { name: 'Phone Number', value: 'phoneNumber' },
  { name: 'Email Address', value: 'emailAddress' },
  { name: 'Qualification', value: 'qualification' },
  { name: 'Certificate Number', value: 'certificateNumber' },
  { name: 'Expiration Date', value: 'expirationDate' },
  { name: 'Years Of Experience', value: 'yearsOfExperience' },
  { name: 'Link', value: 'link' }
]
export const DataUserFields = [
  { name: 'Name', value: 'name' },
  { name: 'Email', value: 'email' },
  { name: 'Company', value: 'company' }
]
export const DataBuildingFields = [
  { name: 'Building Name', value: 'buildingName' },
  { name: 'Building Use', value: 'buildingUse' },
  { name: 'Open 24/7', value: 'open247' },
  { name: 'Year Built', value: 'buildYear' },
  { name: 'Number of Above Grade Floors', value: 'floorCount' },
  { name: 'Number of Below Grade Floors', value: 'belowGradeFloorCount' },
  { name: 'Square Footage', value: 'squareFeet' },
  { name: 'Address', value: 'address' },
  { name: 'Report Date', value: 'createdDate' },
  { name: 'Client', value: 'clientName' },
  { name: 'Site', value: 'siteName' },
  { name: 'Borough', value: 'borough' },
  { name: 'Block', value: 'block' },
  { name: 'Tax Lot', value: 'taxLot' },
  { name: 'BIN', value: 'bin' },
  { name: 'EER', value: 'eer' },
  { name: 'Building Use List', value: 'useList' },
  { name: 'Use List with Square Footage', value: 'listSquareFeet' }
]

export const yearRange = [
  { key: '12', label: 'Last 12 months', value: '12' },
  { key: '24', label: 'Last 24 months', value: '24' },
  { key: '36', label: 'Last 36 months', value: '36' },
  { key: 'Custom', label: 'Custom', value: 'Custom' }
]

export const LaborCostsFields = [
  {
    name: 'General Labor/Unit Cost',
    label: 'general_labor_unit',
    fields: [
      {
        name: 'Unit',
        label: 'general_labor_unit_cost',
        unit: '$/unit',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'general_labor_unit_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'general_labor_unit_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'General Labor Rate',
    label: 'general_labor_rate',
    fields: [
      {
        name: 'Unit',
        label: 'general_labor_rate_cost',
        unit: '$/hr',
        inputType: 'rate'
      },
      {
        name: 'Hours',
        label: 'general_labor_rate_hours',
        unit: 'hrs',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'general_labor_rate_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Day Labor/Unit Cost',
    label: 'day_labor_unit',
    fields: [
      {
        name: 'Unit',
        label: 'day_labor_unit_cost',
        unit: '$/unit',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'day_labor_unit_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'day_labor_unit_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Day Labor Rate',
    label: 'day_labor_rate',
    fields: [
      {
        name: 'Unit',
        label: 'day_labor_rate_cost',
        unit: '$/hr',
        inputType: 'rate'
      },
      {
        name: 'Hours',
        label: 'day_labor_rate_hours',
        unit: 'hrs',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'day_labor_rate_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Overnight Labor/Unit Cost',
    label: 'night_labor_unit',
    fields: [
      {
        name: 'Unit',
        label: 'night_labor_unit_cost',
        unit: '$/unit',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'night_labor_unit_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'night_labor_unit_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Overnight Labor Rate',
    label: 'overnight_labor_rate',
    fields: [
      {
        name: 'Unit',
        label: 'overnight_labor_rate_cost',
        unit: '$/hr',
        inputType: 'rate'
      },
      {
        name: 'Hours',
        label: 'overnight_labor_rate_hours',
        unit: 'hrs',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'overnight_labor_rate_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Prevailing Wages/Unit Cost',
    label: 'prevailing_wages_unit',
    fields: [
      {
        name: 'Unit',
        label: 'prevailing_wages_unit_cost',
        unit: '$/unit',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'prevailing_wages_unit_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'prevailing_wages_unit_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Prevailing Wages',
    label: 'prevailing_wages_rate',
    fields: [
      {
        name: 'Unit',
        label: 'prevailing_wages_rate_cost',
        unit: '$/hr',
        inputType: 'rate'
      },
      {
        name: 'Hours',
        label: 'prevailing_wages_rate_hours',
        unit: 'hrs',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'prevailing_wages_rate_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  }
]

export const MaterialsCostsFields = [
  {
    name: 'Material Unit Cost',
    label: 'material',
    fields: [
      {
        name: 'Cost',
        label: 'material_unit_cost',
        unit: '$',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'material_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'material_cost',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Additional Material Cost',
    label: 'additional_materials_cost',
    fields: [
      {
        name: 'Cost',
        label: 'additional_materials_cost',
        unit: '$',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'additional_materials_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'additional_materials_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Total Accessories Cost',
    label: 'total_accessories_cost',
    unit: '$'
  },
  {
    name: 'Vendor Cost',
    label: 'vendor',
    fields: [
      {
        name: 'Cost',
        label: 'vendor_cost',
        unit: '$',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'vendor_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'vendor_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'New Installation Fee',
    label: 'new_installation',
    fields: [
      {
        name: 'Cost',
        label: 'new_installation_fee',
        unit: '$',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'new_installation_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'new_installation_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Disposal Fee',
    label: 'disposal',
    fields: [
      {
        name: 'Cost',
        label: 'disposal_fee',
        unit: '$',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'disposal_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'disposal_total',
        unit: '$',
        inputType: 'rate'
      }
    ]
  },
  {
    name: 'Site-Specific Installation Factors',
    label: 'installation_factors',
    unit: '$'
  },
  {
    name: 'Environment Unit Cost',
    label: 'environment',
    fields: [
      {
        name: 'Cost',
        label: 'environment_unit_cost',
        unit: '$',
        inputType: 'rate'
      },
      {
        name: 'Quantity',
        label: 'environment_quantity',
        inputType: 'number'
      },
      {
        name: 'Total',
        label: 'total_environment_unit_cost',
        unit: '$',
        inputType: 'rate'
      }
    ]
  }
]
